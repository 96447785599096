import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@admin/store/user'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomePage.vue'),
    meta: { layout: 'main', auth: true },
  },
  {
    path: '/styleguide',
    name: 'Styleguide',
    component: () => import('../views/StyleguidePage.vue'),
    meta: { layout: 'main', auth: false },
  },
  {
    path: '/subsidies',
    name: 'SubsidiesList',
    meta: { layout: 'main', auth: true },
    redirect: () => {
      return { name: 'OpenSubsidies' }
    },
    children: [
      {
        path: 'ongoing',
        name: 'OpenSubsidies',
        component: () => import('../views/subsidies/SubsidiesList.vue'),
      },
      {
        path: 'closed',
        name: 'ClosedSubsidies',
        component: () => import('../views/subsidies/SubsidiesList.vue'),
      },
      {
        path: 'planned',
        name: 'PlannedSubsidies',
        component: () => import('../views/subsidies/SubsidiesList.vue'),
      },
      {
        path: 'drafts',
        name: 'DraftedSubsidies',
        component: () => import('../views/subsidies/SubsidiesList.vue'),
      },
      {
        path: 'director_approval_in_progress',
        name: 'DirectorApprovalSubsidies',
        component: () => import('../views/subsidies/SubsidiesList.vue'),
      },
      {
        path: 'create',
        name: 'CreateSubsidy',
        component: () => import('../views/subsidies/CreateSubsidy.vue'),
      },
      {
        path: ':id',
        name: 'SubsidyDetails',
        component: () => import('../views/subsidies/SubsidyDetails.vue'),
      },
      {
        path: 'edit/:id',
        name: 'EditSubsidy',
        component: () => import('../views/subsidies/CreateSubsidy.vue'),
      },
      {
        path: 'copy/:id',
        name: 'CopySubsidy',
        component: () => import('../views/subsidies/CreateSubsidy.vue'),
      },
    ],
  },
  {
    path: '/users',
    name: 'Users',
    meta: { layout: 'main', auth: true },
    redirect: () => {
      return { name: 'UsersList' }
    },

    children: [
      {
        path: '',
        name: 'UsersList',
        component: () => import('../views/users/UsersList.vue'),
      },
      {
        path: ':name',
        name: 'UsersDetails',
        component: () => import('../views/users/UsersDetails.vue'),
      },
    ],
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: { layout: 'main', auth: true },
    redirect: () => {
      return { name: 'ProfilePage' }
    },

    children: [
      {
        path: '',
        name: 'ProfilePage',
        component: () => import('../views/profile/ProfilePage.vue'),
      },
      {
        path: 'edit',
        name: 'EditProfile',
        component: () => import('../views/profile/EditProfile.vue'),
      },
      {
        path: 'change_password',
        name: 'ChangePassword',
        component: () => import('../views/profile/ChangePassword.vue'),
      },
    ],
  },
  {
    path: '/applications',
    name: 'Applications',
    meta: { layout: 'main', auth: true },
    redirect: () => {
      return { name: 'OpenSubsidies' }
    },
    children: [
      {
        path: 'list',
        name: 'ApplicationsList',
        component: () =>
          import('@admin/views/applications/ApplicationsList.vue'),
      },
      {
        path: ':id',
        name: 'ApplicationDetails',
        component: () =>
          import('@admin/views/applications/ApplicationDetails.vue'),
      },
    ],
  },
  {
    path: '/activity_log',
    name: 'ActivityLog',
    meta: { layout: 'main', auth: true },
    redirect: () => {
      return { name: 'ActivityLogList' }
    },
    children: [
      {
        path: '',
        name: 'ActivityLogList',
        component: () => import('@admin/views/activityLog/activityLogList.vue'),
      },
      {
        path: 'subsidy/:id',
        name: 'SubsidyActivityLogDetails',
        component: () =>
          import('@admin/views/activityLog/SubsidyActivityLogDetails.vue'),
      },
      {
        path: 'application/:id',
        name: 'ApplicationActivityLogDetails',
        component: () =>
          import('@admin/views/activityLog/ApplicationActivityLogDetails.vue'),
      },
      {
        path: 'appeal/:id',
        name: 'AppealActivityLogDetails',
        component: () =>
          import('@admin/views/activityLog/AppealActivityLogDetails.vue'),
      },
    ],
  },
  {
    path: '/appeals',
    name: 'Appeals',
    meta: { layout: 'main', auth: true },
    redirect: () => {
      return { name: 'AppealsList' }
    },
    children: [
      {
        path: 'list',
        name: 'AppealsList',
        component: () => import('../views/appeals/AppealsList.vue'),
      },
      {
        path: ':id',
        name: 'AppealDetails',
        component: () => import('../views/appeals/AppealDetails.vue'),
      },
    ],
  },
  {
    path: '/payment-claims',
    name: 'PaymentClaims',
    meta: { layout: 'main', auth: true },
    redirect: () => {
      return { name: 'PaymentClaimsList' }
    },
    children: [
      {
        path: 'list',
        name: 'PaymentClaimsList',
        component: () =>
          import('../views/paymentClaims/ListOfPaymentClaims.vue'),
      },
      {
        path: ':id',
        name: 'PaymentClaimsDetails',
        component: () =>
          import('../views/paymentClaims/paymentClaimDetails.vue'),
      },
    ],
  },
  {
    path: '/sign_in',
    name: 'SignIn',
    component: () => import('../views/auth/SignIn.vue'),
    meta: { layout: 'auth', auth: false },
    beforeEnter: (_to, _from, next) => {
      const userStore = useUserStore()
      if (userStore.user) {
        next({ name: 'Home' })
      } else {
        next()
      }
    },
  },

  {
    path: '/forgot_password',
    name: 'ForgotPassword',
    component: () => import('../views/auth/ForgotPassword.vue'),
    meta: { layout: 'auth', auth: false },
    beforeEnter: (_to, _from, next) => {
      const userStore = useUserStore()
      if (userStore.user) {
        next({ name: 'Home' })
      } else {
        next()
      }
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
  exactActiveClass: 'active',
})

router.beforeEach(async (to, from, next) => {
  const requireAuth = to.meta.auth
  const userStore = useUserStore()
  if (!userStore.isInitialized) {
    await userStore.fetchUser()
  }
  if (requireAuth && !userStore.user && to.path !== '/sign_in') {
    next({ name: 'SignIn' })
  } else {
    next()
  }
})

export default router
