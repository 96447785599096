<template>
  <div class="aside">
    <div class="aside-main">
      <div class="aside-block">
        <router-link
          class="aside-section-link body bold"
          :to="{ name: 'SubsidiesList' }"
        >
          <div class="link-icon"><SubsidySchemesIcon /></div>

          <span>{{ $t('aside.subsidies.title') }}</span>
          <span></span>
        </router-link>
        <router-link
          class="aside-section-link body bold"
          :to="{ name: 'ApplicationsList' }"
        >
          <div class="link-icon">
            <ApplicationIcon />
          </div>

          <span>{{ $t('application.my_applications') }}</span>
          <span></span>
        </router-link>
        <router-link class="aside-link" :to="{ name: 'ActiveApplications' }">
          <span
            >{{ $t('application.active') }}
            <template
              v-if="
                countersData &&
                (countersData.active_application_count ||
                  countersData.active_application_count == 0)
              "
              >{{ '(' + countersData.active_application_count + ')' }}</template
            ></span
          >
        </router-link>
        <router-link class="aside-link" :to="{ name: 'HistoryApplications' }">
          <span
            >{{ $t('application.history') }}
            <template
              v-if="
                countersData &&
                (countersData.history_application_count ||
                  countersData.history_application_count == 0)
              "
              >{{
                '(' + countersData.history_application_count + ')'
              }}</template
            ></span
          >
        </router-link>
        <router-link class="aside-link" :to="{ name: 'DraftApplications' }">
          <span
            >{{ $t('application.draft') }}
            <template
              v-if="
                countersData &&
                (countersData.draft_application_count ||
                  countersData.draft_application_count == 0)
              "
              >{{ '(' + countersData.draft_application_count + ')' }}</template
            ></span
          >
        </router-link>

        <router-link
          class="aside-section-link body bold"
          :to="{ name: 'AppealsList' }"
        >
          <div class="link-icon">
            <AppealsIcon />
          </div>

          <span>{{ $t('application.my_appeals') }}</span>
          <span></span>
        </router-link>
        <router-link class="aside-link" :to="{ name: 'OpenAppeals' }">
          <span
            >{{ $t('application.open') }}
            <template
              v-if="
                countersData &&
                (countersData.open_appeal_count ||
                  countersData.open_appeal_count == 0)
              "
              >{{ '(' + countersData.open_appeal_count + ')' }}</template
            ></span
          >
        </router-link>
        <router-link class="aside-link" :to="{ name: 'ClosedAppeals' }">
          <span
            >{{ $t('application.closed') }}
            <template
              v-if="
                countersData &&
                (countersData.closed_appeal_count ||
                  countersData.closed_appeal_count == 0)
              "
              >{{ '(' + countersData.closed_appeal_count + ')' }}</template
            ></span
          >
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import SubsidySchemesIcon from '@user/components/icons/SubsidySchemesIcon.vue'
import AppealsIcon from '@admin/components/icons/AppealsIcon.vue'
import ApplicationIcon from '@admin/components/icons/ApplicationIcon.vue'
import { ref, onMounted } from 'vue'
import baseAxios from '@admin/axios'

const countersData = ref(null)
const isLoading = ref(false)

onMounted(async () => {
  try {
    await getCountersData()
  } catch (err) {
    console.log(err)
  }
})
/**
 * Fetches application data from the store asynchronously.
 * @async
 */
const getCountersData = async () => {
  try {
    isLoading.value = true
    const { data } = await baseAxios.get(`stats/sidebar_stats`)
    countersData.value = data
  } catch (error) {
    console.log(error)
  } finally {
    isLoading.value = false
  }
}
</script>

<style lang="scss" scoped>
.aside {
  @include md {
    display: none;
  }

  position: sticky;
  top: 110px;

  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: space-between;

  width: 411px;
  min-width: 240px;

  background: var(--app-color-white);

  &-block {
    width: 100%;
    margin-bottom: 4px;
  }

  &-section-link {
    display: flex;
    align-items: center;

    width: 100%;
    padding: 10px;

    font-size: 16px;
    color: var(--app-color-black);
    text-decoration: none;

    border-radius: 4px;
    box-shadow: none;

    .link-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 24px;
      height: 24px;
      margin-right: 10px;

      transition: all 0.2s;
    }

    &.active {
      color: var(--app-color-primary);
      background-color: var(--app-color-blue-100);
    }

    &:hover,
    &:active {
      color: var(--app-color-primary);
    }

    & + .aside-link {
      margin-top: 4px;
    }
  }

  &-link {
    display: flex;
    align-items: center;

    margin-left: 44px;

    font-size: 16px;
    line-height: 19px;
    color: var(--app-color-black);
    text-decoration: none;

    background: none;
    box-shadow: none;

    &.no-active {
      font-weight: 400;
      color: var(--app-color-black);
    }

    &.router-link-exact-active:not(.no-active) {
      font-weight: 500;
      color: var(--app-color-primary);
    }

    &:hover,
    &:active {
      color: var(--app-color-primary);
    }

    & + .aside-link {
      margin-top: 16px;
    }
  }

  &-bottom {
    &-profile {
      display: flex;
      align-items: center;
      justify-content: space-between;

      height: 72px;
      margin: 0 -16px;
      padding: 24px 24px 24px 30px;

      color: #000;
      text-decoration: none;

      background: var(--app-color-white);
      border-top: 1px solid var(--app-color-gray-200);

      transition: all 0.2s;

      &:hover,
      &:active {
        color: var(--app-color-primary);
      }

      &.active {
        background-color: var(--app-color-blue-100);
      }

      .link-icon {
        color: var(--app-color-primary);
      }
    }
  }
}

.active {
  font-weight: 500;
  color: var(--app-color-primary);
}
</style>
